import React, { useState, useEffect, useRef } from 'react'
import './project.scss'

import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const Project = ({ project }) => {

    const navigate = useNavigate();

    return (
        <div className={"project-page-container" + (project.inverted ? " inverted" : "")}>
            <div className="project-header-container">
                <span className="return" onClick={() => navigate("/")}>Return</span>
                <span className="project-name">{project.name}</span>

            </div>
            <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0} duration={0.3} animateOnce={true}>
                <p className="project-description">{project.description}</p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" delay={800} offset={0} duration={0.3} animateOnce={true}>
                <span className="project-summary">{project.year}, {project.type}, {project.client}</span>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" delay={1100} offset={0} duration={0.3} animateOnce={true}>
                <div className="pictures-container">
                    {project.res.map((resource, index) => (
                        resource.includes(".mp4") ? (
                            <div className={"res-content-container " + (resource.includes(".mp4") ? "video-container" : "")} key={index}>
                                <video className="project-video" autoPlay loop muted playsInline key={index}>
                                    <source src={resource} type="video/mp4"></source>
                                </video>
                            </div>

                        ) : (
                            <div className="res-content-container" key={index}>
                                <img className={"project-picture " + project.display_mode} src={resource} />
                            </div>
                        )
                    ))}
                </div>
            </AnimationOnScroll>
        </div>
    )
}