import './App.scss';

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation } from 'react-router-dom'
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import ScrollToTop from "./ScrollToTop";
import NotFound from "pages/notfound";
import Home from "pages/home";
import Project from "pages/project"
import projects from 'pages/home/projects'

const routes = [
    {
        path: '/',
        title: 'Matthew Nam',
        description: 'Matthew Nam Portfolio',
        Component: Home,
        props: null
    }
]

projects.forEach(project => {
    routes.push({
        path: '/' + project.path,
        title: project.name,
        description: project.description,
        Component: Project,
        props: { project: project }
    })
})

const PageLayout = ({ children }) => children;

const pageVariants = {
    initial: {
        opacity: 0
    },
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
};

const pageTransition = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3
};

const AnimationLayout = () => {
    const { pathname } = useLocation();
    return (
        <PageLayout>
            <motion.div
                key={pathname}
                initial="initial"
                animate="in"
                variants={pageVariants}
                transition={pageTransition}
            >
                <Outlet />
            </motion.div>
        </PageLayout>
    );
};

function RoutesContainer() {

    const location = useLocation();
    const { pathname, key } = location

    return (
        <Routes location={location}>
            <Route element={<AnimationLayout />}>
                {routes.map(({ path, title, description, Component, props }) => (
                    <Route key={path} path={path} element={
                        <React.Fragment>
                            <Helmet>
                                <meta charSet="utf-8" name="description" content={description} />
                                <title>{title}</title>
                            </Helmet>
                            <Component {...props} />
                        </React.Fragment>
                    } />
                ))}
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    )
}

// Main app
function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="main-div">
                <RoutesContainer />
            </div>
        </Router>
    )
}

export default App;
