import vs_posters from 'assets/vansinderen/vs-posters.png'
import vs_preview from 'assets/vansinderen/vs-preview.mp4'

import dear_pic from 'assets/dear/dear-akzidenz.png' 
import dear_preview from 'assets/dear/preview.mp4' 

import dxnovo1 from 'assets/dxnovo/p1.png' 
import dxnovo_preview from 'assets/dxnovo/preview.mp4' 

import proxdis1 from 'assets/proxdis/proxdis1.png' 
import proxdis2 from 'assets/proxdis/proxdis2.png' 
import proxdis3 from 'assets/proxdis/proxdis3.png' 
import proxdis4 from 'assets/proxdis/proxdis4.png' 
import proxdis_preview from 'assets/proxdis/preview.mp4' 

import odinai1 from 'assets/odinai/odinai1.png' 
import odinai_preview from 'assets/odinai/preview.mp4' 

import flashback_11 from 'assets/flashback-1/ff1.png' 
import flashback_1preview from 'assets/flashback-1/preview.png' 

import flashback_21 from 'assets/flashback-2/ff2.png' 
import flashback_2preview from 'assets/flashback-2/preview.png' 

import flashback_31 from 'assets/flashback-3/ff3.png' 
import flashback_3preview from 'assets/flashback-3/preview.png' 

import onplaces1 from 'assets/onplaces/p1.png'
import onplaces2 from 'assets/onplaces/p2.png'
import onplaces3 from 'assets/onplaces/p3.png'
import onplaces4 from 'assets/onplaces/p4.png'
import onplaces5 from 'assets/onplaces/p5.png'
import onplaces6 from 'assets/onplaces/p6.png'
import onplaces7 from 'assets/onplaces/p7.png'
import onplaces_showcase from 'assets/onplaces/onplaces_showcase.png'
import book from 'assets/onplaces/book.png'

import small_cover from 'assets/small/cover.png'
import small_cover_vid from 'assets/small/cover_vid.mp4'
import small1 from 'assets/small/p1.png'
import small2 from 'assets/small/p2.png'
import small3 from 'assets/small/p3.png'
import small4 from 'assets/small/p4.png'
import small5 from 'assets/small/p5.png'
import small6 from 'assets/small/p6.png'

import chekhov_intro from 'assets/chekhov/cg-intro.png'
import chekhov_brand from 'assets/chekhov/cg-brand.png'
import chekhov_deck_intro from 'assets/chekhov/cg-deck-intro.png'
import chekhov_deck from 'assets/chekhov/cg-deck.png'
import chekhov_posters_intro from 'assets/chekhov/cg-posters-intro.png'
import chekhov_posters from 'assets/chekhov/cg-posters.png'
import chekhov_showcase from 'assets/chekhov/cg-preview.mp4'

import lore1 from 'assets/lore/Slide1.png'
import lore2 from 'assets/lore/Slide2.png'
import lore3 from 'assets/lore/Slide3.png'
import lore4 from 'assets/lore/Slide4.png'
import lore5 from 'assets/lore/Slide5.png'
import lore6 from 'assets/lore/Slide6.png'
import lore7 from 'assets/lore/Slide7.png'
import lore8 from 'assets/lore/Slide8.png'
import lore9 from 'assets/lore/Slide9.png'
import lore10 from 'assets/lore/Slide10.png'
import lore11 from 'assets/lore/Slide11.png'
import lore12 from 'assets/lore/Slide12.png'
import lore_showcase from 'assets/lore/lore-showcase.png'

import chaos1 from 'assets/chaos/Pitchdeck-Final1.png'
import chaos2 from 'assets/chaos/Pitchdeck-Final2.png'
import chaos3 from 'assets/chaos/Pitchdeck-Final3.png'
import chaos4 from 'assets/chaos/Pitchdeck-Final4.png'
import chaos5 from 'assets/chaos/Pitchdeck-Final5.png'
import chaos6 from 'assets/chaos/Pitchdeck-Final6.png'
import chaos7 from 'assets/chaos/Pitchdeck-Final7.png'
import chaos8 from 'assets/chaos/Pitchdeck-Final8.png'
import chaos_showcase from 'assets/chaos/showcase.png'

import njf1 from 'assets/njf/set1.png'
import njf2 from 'assets/njf/set2.png'
import njf_preview from 'assets/njf/preview.mp4'

import nexrealm1 from 'assets/nexrealm/nexrealm1.png'

const projects_raw = [
    {
        name: "Flashback Friday",
        path: "ff3-poster",
        year: 2024,
        type: "Posters",
        client: "for Bain & Company",
        code: 1,
        description: "Poster for Bain & Company internal events.",
        res: [flashback_31],
        thumbnail: flashback_3preview,
        aspect: 0.647,
    },
    {
        name: "Predictive AI for Oil & Gas",
        path: "oa-logo",
        year: 2024,
        type: "Logo",
        client: "for Odin AI",
        code: 1,
        description: "Odin AI's data platform helps oil & gas companies maximize production while minimizing environmental impact. In addition to designing their logo, I supported fundraising strategy and pitch deck development.",
        video: true,
        res: [odinai1],
        thumbnail: odinai_preview,
        aspect: 1.1667,
    },
    {
        name: "Flashback Friday",
        path: "ff2-poster",
        year: 2024,
        type: "Posters",
        client: "for Bain & Company",
        code: 1,
        description: "Poster for Bain & Company internal events.",
        res: [flashback_21],
        thumbnail: flashback_2preview,
        aspect: 0.647,
    },
    {
        name: "Nexrealm",
        path: "nr-logo",
        year: 2024,
        type: "Logo",
        client: "for Nexrealm",
        code: 1,
        description: "Nexrealm is a stealth start-up with $2.5 million in seed capital. More to come.",
        res: [nexrealm1],
        thumbnail: nexrealm1,
        aspect: 1.579,
    },
    {
        name: "Where Healthcare and Industry Unite",
        path: "pd-logo",
        year: 2023,
        type: "Logo",
        client: "for PROXDIS",
        code: 1,
        description: "PROXDIS builds software tools for physicians. In mid-2023, their founders approached me seeking help with a rebrand. Over a three month timeline, I designed their bespoke typemark, logomark, typography, and color palette.",
        video: true,
        res: [proxdis1, proxdis2, proxdis3, proxdis4],
        thumbnail: proxdis_preview,
        aspect: 1.1667,
    },
    {
        name: "Flashback Friday",
        path: "ff1-poster",
        year: 2023,
        type: "Posters",
        client: "for Bain & Company",
        code: 1,
        description: "Posters for Bain & Company internal events.",
        res: [flashback_11],
        thumbnail: flashback_1preview,
        aspect: 0.647,
    },
    {
        name: "Small Landscapes",
        path: "sl-book",
        year: 2023,
        type: "Artist's Book",
        client: "Personal",
        code: 2,
        description: "Small Landscapes is a personal manifesto dedicated to close looking, to doing nothing, to placing form over function, to rethinking productivity, to taking things slow, to walking, to meandering, to stopping to smell the roses, to people watching, to designing books that no one is meant to read, to listening to Ira Glass tell a story about some 6-year-old kid who just uncovered the magic of Santa, to spending time outside, to paying mindful attention. To appreciating the beauty that's always there, no matter how small.",
        res: [small_cover_vid, small1, small2, small3, small4, small5, small6],
        thumbnail: small_cover,
        aspect: 0.8286,
    },
    {
        name: "Empowering Healthcare Digitalization",
        path: "dxn-logo",
        year: 2023,
        type: "Logo",
        client: "for DxNovo",
        code: 1,
        description: "DxNovo is a consulting firm working at the intersection of strategy and technology for healthcare companies.",
        video: true,
        res: [dxnovo1],
        thumbnail: dxnovo_preview,
        aspect: 1.1667,
    },
    {
        name: "Jazz in Newport",
        path: "nj-poster",
        year: 2023,
        type: "Poster",
        client: "Coursework",
        code: 2,
        description: "The Newport Jazz Festival is an annual multi-day jazz music festival held every summer in Newport, Rhode Island. I designed two sequential poster sets promoting 2023's upcoming shows, applying typography and image-making in a manner that would communicate jazz's dynamism and vibrancy.",
        res: [njf1, njf2],
        thumbnail: njf_preview,
        video: true,
        display_mode: "regular",
        video: true,
        aspect: 0.647,
    },
    {
        name: "On Places",
        path: "op-book",
        year: 2023,
        type: "Magazine",
        client: "Coursework",
        code: 2,
        description: "On Places is a quarterly magazine dedicated to highlighting those places that have strong cultural or societal influence, particularly those that reflect and inform how we express ourselves and connect with others. In this issue of On Places, we focus on the cafe, that unassuming coffee shop just around the corner, that beloved hotspot that invites reunions with old friends and chance encounters, that mom-and-pop hole-in-the-wall where enjoying food and beverage is just as suitable an activity as unifying vast political movements.",
        res: [onplaces_showcase, onplaces1, onplaces2, onplaces3, onplaces4, onplaces5, onplaces6, onplaces7],
        thumbnail: book,
        inverted: true,
        display_mode: "regular",
        aspect: 1.4109,
    },
    {
        name: "Guitar Effects for the Digital Age",
        path: "ca-deck",
        year: 2023,
        type: "Pitch Deck",
        client: "for Chaos Audio",
        code: 1,
        description: "Chaos Audio manufactures a guitar pedal suited for the digital age. While designing Chaos Audio's pitch deck, I aimed to convey their youthful energy while maintaining an appropriate level of practicality. Throughout the design process, I helped the founding team develop their slide-to-slide narrative and copy. Some slides omitted due to confidential information.",
        res: [chaos1, chaos2, chaos3, chaos4, chaos5, chaos6, chaos7, chaos8],
        thumbnail: chaos_showcase,
        display_mode: "presentation",
        aspect: 1.3033,
    },
    {
        name: "Burst Your Bubble",
        path: "cg-identity",
        year: 2023,
        type: "Identity",
        client: "for Chekhov's Gum",
        code: 1,
        description: "Chekhov's Gum is an experimental theatre production company that hosts shows across New York and New Haven. Through Tsai CITY, Yale's think tank, I led a rebranding effort that aimed to capture their rowdy and playful spirit through logo design, typography, and color. Along with developing their brand guidelines, I designed their pitch deck and event posters.",
        res: [chekhov_intro, chekhov_brand, chekhov_posters_intro, chekhov_posters, chekhov_deck_intro, chekhov_deck],
        thumbnail: chekhov_showcase,
        video: true,
        aspect: 0.75,
    },
    {
        name: "Your Next Blockchain Explorer",
        path: "ls-deck",
        year: 2023,
        type: "Sales Deck",
        client: "for LoreScan",
        code: 1,
        description: "LoreScan allows enterprise and retail clients to explore the blockchain in an interpretable way. Having raised $1.7 million in venture capital and nearing the release of their MVP, the founders approached me to design their enterprise sales deck. Aiming to create something approachable and visually concise, I focused on imposing a modern aesthetic and developing an effective informational hierarchy.",
        res: [lore1, lore2, lore3, lore4, lore5, lore6, lore7, lore8, lore9, lore10, lore11, lore12],
        thumbnail: lore_showcase,
        display_mode: "presentation",
        aspect: 1.0345,
    },
    {
        name: "Dear Akzidenz",
        path: "da-poster",
        year: 2023,
        type: "Poster",
        client: "Coursework",
        code: 2,
        description: "These type specimens feature Akzidenz-Grotesk, a key typeface of the Swiss International aesthetic. Poster content is taken from The New York Times' Metropolitan Diary, a series of weekly short stories submitted by New Yorkers.",
        res: [dear_pic],
        thumbnail: dear_preview,
        video: true,
        aspect: 0.647,
    },
    {
        name: "Book Collecting!",
        path: "vs-poster",
        year: 2022,
        type: "Poster",
        client: "Competition Submission",
        code: 2,
        description: "The following are a set of posters submitted as candidates for The Adrian Van Sinderen Book Collecting Prizes poster competition.",
        res: [vs_posters],
        thumbnail: vs_preview,
        display_mode: "regular",
        video: true,
        aspect: 0.7727,
    }
]

export default projects_raw.sort(
    (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0);
