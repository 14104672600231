import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { AnimationOnScroll } from 'react-animation-on-scroll';

import './home.scss'
import projects from './projects'

export const Home = ({ }) => {
    return (
        <div className="home-container">
            <AboutMe />
            <ProjectSummary />
        </div>
    )
}

export const HomeHeader = ({ title }) => {
    return (
        <div className="home-header-container">
            <p>{title}</p>
        </div>
    )
}

const filters = [
    { code: 0, name: "Mixer (15)", projPerRow: [4, 4, 4, 3], grow: 0.8 },
    { code: 1, name: "Client Work (10)", projPerRow: [4, 4, 2], grow: 2 },
    { code: 2, name: "Misc (5)", projPerRow: [4, 1], grow: 3 },
]

const ProjectFilterHeader = ({ filter, setFilter }) => {
    return (
        <div className="home-header-container filter-container">
            {filters.map((filterSetting, i) => (
                <p
                    className={filter == filterSetting.code ? "selected" : ""}
                    onClick={() => setFilter(filterSetting.code)}
                    key={i}>
                    {filterSetting.name}
                </p>
            ))
            }
        </div>
    )
}

const ProjectCell = ({ project, navigate }) => {
    return (
        <div className={"table-cell" + (project.inverted ? " inverted-color" : "")} onClick={() => {
            navigate("/" + project.path)
        }} style={{ "flex": project.aspect }}>
            {project.video ? (
                <video autoPlay loop muted playsInline key={project.thumbnail}>
                    <source src={project.thumbnail} type="video/mp4"></source>
                </video>
            ) : (
                <img src={project.thumbnail} />
            )}
            <div className="table-cell-info">
                <p className="cell-main-title">{project.name}, {project.year}</p>
                <p>{project.type}, {project.client}</p>
            </div>
        </div>
    )
}

const ProjectSummary = () => {

    const navigate = useNavigate();
    const [filter, setFilter] = useState(0)

    const singleColumn = useMediaQuery({ query: '(max-width: 720px)' })
    const singleColumnProjects = useMediaQuery({ query: '(max-width: 500px)' })

    const getOrderedProjects = (projectsPerRow, filterCode) => {
        var filterProjects = projects
        if (filterCode != 0) {
            filterProjects = projects.filter(project => project.code == filterCode)
        }

        const ret = projectsPerRow.map((num, i) => {
            const currIndex = projectsPerRow.slice(0, i).reduce((partialSum, a) => partialSum + a, 0)
            const rowProjects = filterProjects.slice(currIndex, currIndex + num)
            return rowProjects
        })
        return ret
    }

    return (
        <div className="project-summary-container">
            {singleColumn ? <></> : <ProjectFilterHeader filter={filter} setFilter={setFilter} />}
            <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0} duration={0.3} animateOnce={true}>
                <div className="table-container">
                    {singleColumnProjects ? (
                        projects.map((project, j) => (
                            <ProjectCell
                                project={project}
                                navigate={navigate}
                                key={j} />
                        ))
                    ) : (
                        getOrderedProjects(filters[filter].projPerRow, filters[filter].code).map((projectRow, i) => (
                            <div className="table-container-row" key={i}>
                                {projectRow.map((project, j) => (
                                    <ProjectCell
                                        project={project}
                                        navigate={navigate}
                                        key={j} />
                                ))}
                                {i == filters[filter].projPerRow.length - 1 && filters[filter].grow != 0 ? (
                                    <div style={{ cursor: "default", flexGrow: filters[filter].grow }}
                                        className="table-cell">
                                    </div>
                                ) : <></>}
                            </div>
                        ))
                    )}
                </div>
            </AnimationOnScroll>
        </div>
    )
}

const AboutMe = ({ }) => {
    return (
        <div className="about-container">
            <HomeHeader title="About" />
            <div className="about-content-container">
                <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0} duration={0.3} animateOnce={true}>
                    <p className="about-content">
                        <span style={{ color: "blue", fontStyle: "italic" }}>Matthew Nam</span> (b. 2001, Toronto) is a New York based independent graphic designer and <a className="regular-anchor" target="_blank" rel="noopener noreferrer" href="https://www.bain.com/">
                            Bain & Company
                        </a> management consultant.
                    </p>
                    <p className="about-content">
                        He holds a <span className="small-caps">B.S.</span> from <a className="regular-anchor" target="_blank" rel="noopener noreferrer" href="https://www.yale.edu/">
                            Yale
                        </a> ('23) and has accepted early admission to pursue an <span className="small-caps">MBA</span> at <a className="regular-anchor" target="_blank" rel="noopener noreferrer" href="https://www.wharton.upenn.edu/">
                            Wharton
                        </a> ('28).
                    </p>
                    <p className="about-content">
                        Matthew's design practice focuses on brand identities and printed matter. His storytelling aims to be pragmatic, concise, and experimental.
                    </p>
                </AnimationOnScroll>
            </div>
            <div className="about-rights-container">
                <AnimationOnScroll animateIn="animate__fadeIn" delay={500} offset={0} duration={0.3} animateOnce={true}>
                    <p className="about-content">
                        Contact: <a className="regular-anchor" target="_blank" rel="noopener noreferrer" href="mailto: work@matthewnam.com">
                            work@matthewnam.com
                        </a>.
                    </p>
                    <p className="about-rights">All Rights Reserved © 2024</p>
                </AnimationOnScroll>
            </div>
        </div>
    )
}
