import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "animate.css/animate.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// This ensures that on mobile safari, we don't include the menu bar and search bar in our height 
// We set the height of body in all.css
function screenHeight() {
    function checkScreenHeight() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    window.addEventListener('orientationchange', function () {
        // After orientationchange, add a one-time resize event
        var afterOrientationChange = function () {
            checkScreenHeight()
            window.removeEventListener('resize', afterOrientationChange);
        };
        window.addEventListener('resize', afterOrientationChange);
    })

    checkScreenHeight()
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    screenHeight()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
